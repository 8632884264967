import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { removeTrailingIndex } from "src/utils/removeTrailingIndex";

const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

/*

Uses next/head to append elements to the head of the page.
See https://nextjs.org/docs/api-reference/next/head

Important Note: 

title, meta or any other elements (e.g. script) need to be contained as **direct** children of the Head element, 
or wrapped into maximum one level of <React.Fragment> or arrays
otherwise the tags won't be correctly picked up on client-side navigations.

*/

const SEO = ({
  title = "",
  description = "",
  metas = [],
  links = [],
  siteUrl = SITE_URL,
  indexingStrategy,
  pagePath,
}) => {
  const router = useRouter();
  const path = pagePath || removeTrailingIndex(router.asPath);
  const ogUrl = `${siteUrl}${path}`;

  const titleWithMBA =
    title !== ""
      ? `${title} | Much Better Adventures`
      : "Much Better Adventures";

  return (
    <>
      <SEOPageTitle title={titleWithMBA} />

      <SEOMeta
        title={titleWithMBA}
        description={description}
        url={ogUrl}
        metas={metas}
      />

      <SEOLinks links={links} />

      {!!indexingStrategy && (
        <SEOIndexing
          strategy={indexingStrategy}
          pagePath={path}
          canonicalBaseUrl={siteUrl}
        />
      )}
    </>
  );
};

const SEOPageTitle = ({ title = "" }) => (
  <Head>
    <title>{title}</title>
  </Head>
);

const SEOMeta = ({ description = "", title = "", url = "", metas = [] }) =>
  [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: "og:url",
      content: url,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:site`,
      content: "@muchbetterAdven",
    },
    {
      name: `twitter:creator`,
      content: "@muchbetterAdven",
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ]
    .concat(metas)
    .map((item) => (
      <Head key={item.name || item.property}>
        <meta {...item} />
      </Head>
    ));

const SEOLinks = ({ links = [] }) =>
  links.map((item) => (
    <Head key={item.href}>
      <link {...item} />
    </Head>
  ));

const SEOIndexing = ({ strategy, pagePath, canonicalBaseUrl }) => {
  const { robots, canonical } = strategy(pagePath, canonicalBaseUrl);

  return (
    <Head>
      {!!robots && <meta name="robots" content={robots} />}

      {!!canonical && <link rel="canonical" href={canonical} />}
    </Head>
  );
};

export default SEO;
