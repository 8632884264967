import { Button, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { getIconComponent } from "../../utils/icons";

const useStyles = makeStyles(() =>
  createStyles({
    textButton: {
      marginLeft: -6,
      marginRight: -6,
    },
  })
);

const ctaButton = (variant, color, link, text, icon, iconPosition) => {
  const Icon = icon ? getIconComponent(icon) : null;

  const ctaProps = {
    variant: variant || "contained",
    color: color || "primary",
    href: link,
  };

  if (Icon) {
    if (iconPosition !== "end") {
      ctaProps.startIcon = <Icon />;
    } else {
      ctaProps.endIcon = <Icon />;
    }
  }

  const CtaButton = ({ ...props }) => {
    const classes = useStyles();
    const hasCta = link && text;
    return hasCta ? (
      <Button
        {...ctaProps}
        {...props}
        className={variant === "text" ? classes.textButton : ""}
      >
        {text}
      </Button>
    ) : null;
  };

  return CtaButton;
};

export default ctaButton;
