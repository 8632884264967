import Image from "next/image";
import React from "react";

import { Box } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

import Level3 from "./Level3";

const useStyles = makeStyles(() =>
  createStyles({
    containerCircle: {
      maxWidth: "100%",
      position: "relative",
      paddingTop: "100%",
      height: 0,
    },
    imageCircle: {
      borderRadius: "100%",
    },
    imageRounded: {
      borderRadius: 5,
    },
    imageDefault: {},
  })
);
const placeholderImage = {
  url: "http://via.placeholder.com/640",
  width: 640,
  height: 640,
};
const Level3Image = ({
  image = placeholderImage,
  imageStyle,
  unoptimized = false,
  ...rest
}) => {
  const theImage = image !== null ? image : placeholderImage;
  const classes = useStyles();
  const imageProps = {
    src: theImage.url,
    objectFit: "cover",
    className: classes[`image${imageStyle || "Default"}`],
    layout: "fill",
    unoptimized: unoptimized,
  };

  if (imageStyle !== "Circle") {
    imageProps.layout = "responsive";
    imageProps.width = theImage.width;
    imageProps.height = theImage.height;
  }

  return (
    <Level3
      feature={
        <Box className={classes[`container${imageStyle}`]}>
          <Image alt="" {...imageProps} />
        </Box>
      }
      {...rest}
    />
  );
};

export default Level3Image;
