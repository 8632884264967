import React from "react";
import ReactMarkdown from "react-markdown";

import { Box, Grid, Typography } from "@material-ui/core";

import { useStyles } from "../styles";

const vAlignLookup = {
  Center: "center",
  Top: "flex-start",
  Bottom: "flex-end",
};

const Level3 = ({
  title,
  hasBackgroundImage,
  content,
  feature = null,
  vAlign = "Center",
  align,
  Cta,
}) => {
  const classes = useStyles();
  const gridDirection = align === "Left" ? "row-reverse" : "row";

  return (
    <>
      <Box
        position="relative"
        paddingY={[8, 12]}
        paddingX={[2, 4]}
        color={hasBackgroundImage ? "white" : "black"}
      >
        <Grid
          container
          spacing={0}
          alignItems={vAlign ? vAlignLookup[vAlign] : "center"}
          direction={gridDirection}
        >
          <Grid item xs={12} sm={5} md={5}>
            <Box textAlign="center" mb={[3, 0]} mx={[0, 5]}>
              <Box maxWidth={[150, 150, 200]} marginX="auto">
                {feature}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <Typography variant="h3">{title}</Typography>
            <div className={classes.content}>
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
            <Cta />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Level3;
