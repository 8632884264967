import Script from "next/script";
import React from "react";

const TrustBox = () => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <>
      <Script
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="afterInteractive"
      />
      <div
        ref={ref} // We need a reference to this element to load the TrustBox in the effect.
        className="trustpilot-widget" // Renamed this to className.
        data-locale="en-GB"
        data-template-id="5419b732fbfb950b10de65e5"
        data-businessunit-id="518296f4000064000529947e"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="dark"
      >
        <a
          href="https://www.trustpilot.com/review/muchbetteradventures.com"
          target="_blank"
          rel="noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
};
export default TrustBox;
