import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import Level3 from "./Level3";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: "192px !important",
      height: "192px !important",
      maxWidth: "100%",
    },
  })
);

const Level3Icon = ({ Icon, ...rest }) => {
  const classes = useStyles();
  return (
    <Level3 feature={Icon && <Icon className={classes.icon} />} {...rest} />
  );
};

export default Level3Icon;
