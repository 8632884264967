import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      ...theme.typography.body2,
      "& h1": {
        ...theme.typography.h3,
      },
      "& h2": {
        ...theme.typography.h3,
      },
      "& h3": {
        ...theme.typography.h4,
      },
      "& a": {
        color: theme.palette.secondary.main,
        textDecoration: "none",
      },
      "& a:hover": {
        textDecoration: "underline",
      },
    },
  })
);

export { useStyles };
