import React from "react";
import ReactMarkdown from "react-markdown";
import TrustBox from "src/components/Trustpilot/TrustBox";

import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    bgPaper: {
      backgroundImage: "url('/images/_next/paper-bg.png')",
    },
    bgWhite: {
      backgroundColor: "white",
    },
    bgIcon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -100,
      marginLeft: -100,
      height: 200,
      width: 200,
      opacity: 0.1,
    },
    fgIcon: {
      width: 75,
      height: 75,
    },
    tallH1: {
      fontSize: theme.typography.h1.fontSize * 1.4,
      lineHeight: 1.1,
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.h1.fontSize * 0.9,
      },
    },
    content: {
      ...theme.typography.highlight,
      fontSize: theme.typography.highlight.fontSize * 0.8,
      lineHeight: 1.2,
      "& > p": {
        marginBottom: "1em",
        marginTop: 0,
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: 1.2,
      },
    },
  })
);

const PageTitle = ({
  title,
  content,
  align,
  Icon,
  hasBackgroundImage,
  Cta,
  showTrustpilotWidget = false,
  tall = false,
}) => {
  const classes = useStyles();
  return (
    <>
      {Icon && <Icon className={classes.bgIcon} />}
      <Box
        position="relative"
        paddingY={tall ? [16, 32] : [8, 12]}
        textAlign={align ? align.toLowerCase() : "center"}
        color={hasBackgroundImage ? "white" : "inherit"}
      >
        {Icon && <Icon className={classes.fgIcon} />}
        <Typography
          variant="h1"
          gutterBottom={!content}
          className={tall ? classes.tallH1 : ""}
        >
          {title}
        </Typography>
        {content && (
          <div className={classes.content}>
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        )}

        <Cta size="large" />
        {showTrustpilotWidget && (
          <Box position="absolute" left={0} right={0} bottom={24}>
            <TrustBox />
          </Box>
        )}
      </Box>
    </>
  );
};

export default PageTitle;
