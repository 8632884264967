/*

See https://github.com/vercel/next.js/issues/35345

Hopefully when this gets resolved, this can be deleted

*/

const removeTrailingIndex = (path) => {
  const [pathname, querystring] = path.split("?");

  const pathnameWithoutIndex =
    pathname.endsWith("/index/") || pathname.endsWith("/index")
      ? pathname.substring(0, pathname.length - 6)
      : pathname;

  return querystring
    ? `${pathnameWithoutIndex}?${querystring}`
    : pathnameWithoutIndex;
};

export { removeTrailingIndex };
