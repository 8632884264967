import React from "react";

import { Box, Container } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { createStyles, makeStyles } from "@material-ui/styles";

import { getIconComponent } from "../../utils/icons";
import ctaButton from "./ctaButton";
import ImageSection from "./sections/Image";
import Level2 from "./sections/Level2";
import Level3 from "./sections/Level3";
import Level3Icon from "./sections/Level3Icon";
import Level3Image from "./sections/Level3Image";
import PageTitle from "./sections/PageTitle";
import { Photograph } from "../Image";

const variantComponents = {
  "Page title": PageTitle,
  "Level 2": Level2,
  "Level 3": Level3,
  "Level 3 with icon": Level3Icon,
  "Level 3 with image": Level3Image,
  "Full bleed image": ImageSection,
};

const useStyles = makeStyles(() =>
  createStyles({
    bgPaper: {
      backgroundImage: "url('/images/_next/paper-bg-light.png')",
    },
    bgIllustrations: {
      backgroundImage: "url('/images/_next/illustrations-bg-black.png')",
      color: "white",
    },
    bgWhite: {
      backgroundColor: grey[50],
    },
    bgImage: {
      backgroundColor: "black",
    },
    imageAsBg: {
      opacity: 0.6,
    },
  })
);

const Section = ({
  variant,
  background,
  image,
  icon,
  Cta = null,
  ctaVariant = "contained",
  ctaColor = "primary",
  ctaLink,
  ctaIcon,
  ctaIconPosition,
  ctaText,
  ...rest
}) => {
  const Component = variantComponents[variant];
  const classes = useStyles();
  const Icon = background !== "Image" && icon ? getIconComponent(icon) : null;
  const isFullBleedImage = variant === "Full bleed image";
  const hasBackgroundImage =
    image && (background === "Image" || isFullBleedImage);

  return (
    <>
      <Box position="relative" className={classes[`bg${background}`]}>
        {hasBackgroundImage && (
          <Photograph
            priority={variant === "Page title"}
            alt=""
            src={image.url}
            className={
              background === "Image" && !isFullBleedImage
                ? classes.imageAsBg
                : ""
            }
            sizes="100vw"
          />
        )}

        {Component ? (
          <Container maxWidth="md">
            <Component
              hasBackgroundImage={hasBackgroundImage}
              Icon={Icon}
              image={image}
              Cta={
                Cta
                  ? () => Cta
                  : ctaButton(
                      ctaVariant,
                      ctaColor,
                      ctaLink,
                      ctaText,
                      ctaIcon,
                      ctaIconPosition
                    )
              }
              {...rest}
            />
          </Container>
        ) : null}
      </Box>
    </>
  );
};

export default Section;
