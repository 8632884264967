import React from "react";
import ReactMarkdown from "react-markdown";

import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      ...theme.typography.highlight,
    },
  })
);

const Level2 = ({ title, content, align, hasBackgroundImage, Cta }) => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      paddingY={[8, 12]}
      textAlign={align ? align.toLowerCase() : "left"}
      color={hasBackgroundImage ? "white" : "black"}
    >
      <Typography variant="h2">{title}</Typography>
      <div className={classes.content}>
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
      <Cta />
    </Box>
  );
};

export default Level2;
